<template>
  <v-btn :light="isDarkModeEnabled" fab height="35" width="35">
    <v-icon :data-product="pathName" @click="navigateBack">
      {{ mdiArrowLeft }}
    </v-icon>
  </v-btn>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { mdiArrowLeft } from "@mdi/js";

export default {
  name: "ArrowBack",
  props: {
    pathName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      mdiArrowLeft
    };
  },
  computed: {
    ...mapGetters(["isProductActive"]),
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.pathName);
    },
    colorArrow() {
      return this.isDarkModeEnabled ? "#FFFFFF" : "";
    }
  },
  methods: {
    ...mapMutations(["closeProduct"]),
    navigateBack() {
      const product = this.pathName;
      const isActive = this.isProductActive(product);
      this.$store.state.lastClosedByClick = product;
      this.closeProduct(product);
      if (isActive) {
        this.$router.push("/");
      }
    }
  }
};
</script>
